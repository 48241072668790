import { isUndefined, uuid } from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import { createLogger } from '@iheartradio/web.utilities/create-logger';
import { mergeDeep } from 'remeda';
import type { PartialDeep } from 'type-fest';
import { ZodError } from 'zod';

import {
  analyticsData as analyticsDataSchema,
  globalData as globalDataSchema,
} from './schemas.js';
import type {
  Analytics,
  AnalyticsMethods,
  Event,
  GlobalData,
  UncontrolledGlobalData,
} from './types.js';

export function getScreenOrientation() {
  return window.matchMedia('(orientation: portrait)').matches ?
      'portrait'
    : 'landscape';
}

export function createAnalytics(options?: { debug: boolean }): {
  analytics: Analytics;
  useAnalytics: () => Analytics;
} {
  const logger = createLogger({
    enabled: true,
    namespace: '@iheartradio/web.analytics',
    pretty: true,
  });

  async function validate<Name, Payload>(name: Name, fn: () => Payload) {
    try {
      return fn();
    } catch (error: unknown) {
      logger.error(
        `Failed Schema Validation: ${name}`,
        error instanceof ZodError ? error.issues : error,
      );

      throw error;
    }
  }

  let globalData = {
    device: {},
    event: {},
    session: {},
    view: {},
  } as GlobalData;

  const analytics = createEmitter<AnalyticsMethods>({
    async initialize(uncontrolledGlobalData: UncontrolledGlobalData) {
      const date = new Date();
      const now = Date.now();

      const dayOfWeek = date
        .toLocaleString('en-us', { weekday: 'long' })
        .toLowerCase() as GlobalData['device']['dayOfWeek'];

      const timezone = new Intl.DateTimeFormat(undefined, {
        timeZoneName: 'short',
      })
        .formatToParts()
        .find(part => part.type === 'timeZoneName')?.value as string;

      const gpcEnabled =
        'globalPrivacyControl' in window.navigator ?
          (window.navigator.globalPrivacyControl as boolean)
        : false;

      globalData = {
        ...uncontrolledGlobalData,
        device: {
          ...uncontrolledGlobalData.device,
          appSessionId: uuid(),
          callId: uuid(),
          dayOfWeek,
          hourOfDay: date.getHours(),
          language: navigator.language,
          screenOrientation: getScreenOrientation(),
          gpcEnabled,
          timezone,
          userAgent: navigator.userAgent,
        },
        event: {
          capturedTimestamp: now,
          loggedTimestamp: now,
        },
        querystring: Object.fromEntries(
          new URLSearchParams(window.location.search),
        ),
        session: {
          sequenceNumber: 0,
        },
        view: {
          pageURL: window.location.href,
        },
      };

      return validate('initialize', () => globalDataSchema.parse(globalData));
    },

    getGlobalData() {
      return globalData;
    },

    async setGlobalData(
      uncontrolledGlobalData: PartialDeep<UncontrolledGlobalData>,
    ) {
      globalData = mergeDeep(globalData, uncontrolledGlobalData) as GlobalData;

      return validate('setGlobalData', () =>
        globalDataSchema.parse(globalData),
      );
    },

    async track<T extends Event>(event: T) {
      globalData.device.callId = uuid();
      globalData.device.hourOfDay = new Date().getHours();
      globalData.device.screenOrientation = getScreenOrientation();
      globalData.event.capturedTimestamp = Date.now();
      globalData.session.sequenceNumber += 1;
      globalData.view.pageURL = window.location.href;

      const analyticsData = mergeDeep(globalData, event);

      return validate(event.type, () =>
        analyticsDataSchema.parse(analyticsData),
      );
    },
  });

  const isBrowser = !isUndefined(globalThis?.window);

  const parameter =
    isBrowser ?
      new URL(window.location.href).searchParams.get('debug')
    : undefined;

  const debug =
    options?.debug ??
    (isBrowser &&
      (parameter?.includes('true') || parameter?.includes('analytics')));

  if (debug) {
    analytics.subscribe({
      all(method, data) {
        logger.log(`${method}${'type' in data ? `:${data.type}` : ''}`, data);
      },
    });
  }

  return {
    analytics,
    useAnalytics() {
      return analytics;
    },
  } as const;
}
